import { CARD_BORDER_LIGHT } from "ds_legacy/materials/colors";

export const MAP_PLACEHOLDER_COLOR = CARD_BORDER_LIGHT;

export default function Placeholder({
  height,
  width,
}: {
  height: string;
  width?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: width ?? "100%",
        height,
        position: "relative",
        backgroundColor: MAP_PLACEHOLDER_COLOR,
      }}
    />
  );
}
