import { Paper } from "@mui/material";
import { getStaticAsset } from "core/model/config/index";
import RSButton from "ds_legacy/components/RSButton/index";
import { dp, padding } from "ds_legacy/materials/metrics";
import React from "react";
import styled from "styled-components";
import { useTranslations } from "translations";

const MAP_PLACEHOLDER_NEAR_FIELD = getStaticAsset("map-placeholder-1000.webp");

const PAPER_BORDER = dp(32);
const DEFAULT_SIZE = "100%";

const Wrapper = styled.div<{
  height: string;
  width: string;
}>`
  background-image: url("${MAP_PLACEHOLDER_NEAR_FIELD}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: ${(props) => (props.width ? props.width : DEFAULT_SIZE)};
  height: ${(props) => (props.height ? props.height : DEFAULT_SIZE)};

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  text-align: center;
  padding: ${padding(1)};
`;

export default function MapActivation({
  height,
  onActivate,
  title,
  width,
}: {
  height: string;
  onActivate: () => void;
  title?: string;
  width: string;
}) {
  const translations = useTranslations();

  return (
    <Wrapper height={height} width={width}>
      <Paper
        elevation={5}
        style={{
          display: "flex",
          alignSelf: "center",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          width: `calc(${width ?? DEFAULT_SIZE} - ${PAPER_BORDER})`,
          height: `calc(${height ?? DEFAULT_SIZE} - ${PAPER_BORDER})`,
        }}
      >
        {title ? <Title>{title}</Title> : null}
        <RSButton
          id="activate-map"
          loading="na"
          variant="outlined"
          onClick={() => onActivate()}
        >
          {translations.patient.map.mapButton}
        </RSButton>
      </Paper>
    </Wrapper>
  );
}
